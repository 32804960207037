<template>
    <Transition name="modal">
        <div v-if="show" class="modal-mask" id="modal-mask">
            <div class="modal-container" id="modal-container">
                <div class="modal-icon modal-icon--top modal-icon--line">
                    <blue-line></blue-line>
                </div>
                <div class="modal-icon modal-icon--top modal-icon--close">
                    <close-icon @click.prevent="close"></close-icon>
                </div>

                <div class="modal-header">
                    <slot name="header" v-bind="{ close }"></slot>
                </div>

                <div class="modal-body">
                    <slot name="body" v-bind="{ close }"></slot>
                </div>

                <div class="modal-footer">
                    <slot name="footer" v-bind="{ close }"></slot>
                </div>

                <div class="modal-icon modal-icon--bottom modal-icon--line">
                    <blue-line></blue-line>
                </div>
            </div>
        </div>
    </Transition>
</template>

<script>
import { ref, watch, nextTick } from "vue";
import SvgBlueLine from "./SvgBlueLine";
import SvgClose from "./SvgClose";

export default {
    components: {
        'blue-line': SvgBlueLine,
        'close-icon': SvgClose,
    },

    props: {
        show: {
            type: Boolean,
            default: false,
        }
    },

    emits: ['close'],

    setup(props, { emit }) {
        const maskElement = ref(null);

        // close modal when background is clicked
        const onBackgroundClick = (e) => {
            if (e.target === maskElement.value) {
                close();
            }
        };

        const close = () => {
            emit('close');
        };

        watch(() => props.show, (showing, wasShowing) => {
            nextTick(() => {
                maskElement.value = document.getElementById('modal-mask');
                if (maskElement.value && showing) {
                    maskElement.value.addEventListener('click', onBackgroundClick);
                } else if (maskElement.value && wasShowing) {
                    maskElement.value.removeEventListener('click', onBackgroundClick);
                }
            });
        });

        return {
            close,
        };
    }
}
</script>

<style lang="scss">
.modal-icon {
    position: absolute;

    &--top {
        top: 10px;
        left: 0;
    }

    &--bottom {
        bottom: 10px;
        right: 0;
        transform: rotate(180deg);
    }

    svg {
        max-width: 200px;
    }

    &--close {
        left: unset;
        right: 15px;
        top: 15px;

        svg {
            width: 20px;
            transition: all 300ms ease-in-out;

            &:hover {
                cursor: pointer;
                fill: #556070;
            }
        }
    }
}

.modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    transition: opacity 0.3s ease;
}

.modal-container {
    position: relative;
    width: 600px;
    max-width: 95vw;
    margin: auto;
    padding: 50px 30px;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
    border-bottom: 6px solid #0b2343;

    @media (min-width: 500px) {
        padding: 50px;
    }
}

.modal-header h3 {
    margin-top: 0;
    color: #0b2343;
    font-size: 3.5rem;
}

.modal-body {
    margin: 15px 0;
}

.modal-default-button {
    float: right;
}

.modal-enter-from {
    opacity: 0;
}

.modal-leave-to {
    opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}
</style>
<template>
    <svg class="blue-line" width="257" height="10" viewBox="0 0 257 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <line x1="247" y1="5.5" x2="4.37114e-08" y2="5.50002" stroke="url(#paint0_linear_3149_341)"/>
        <circle cx="5" cy="5" r="5" transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 257 10)" fill="#6ACFED"/>
        <defs>
            <linearGradient id="paint0_linear_3149_341" x1="123.5" y1="5.00003" x2="-9.63729" y2="5.00005" gradientUnits="userSpaceOnUse">
                <stop stop-color="#6ACFED"/>
                <stop offset="1" stop-color="#6ACFED" stop-opacity="0"/>
            </linearGradient>
        </defs>
    </svg>
</template>

<script>
export default {}
</script>
<template>
    <svg class="close-icon" width="24" height="25" viewBox="0 0 24 25" xmlns="http://www.w3.org/2000/svg">
        <path d="M23.328 25H19.008L11.808 15.208L4.608 25H0.252L9.468 12.688L0.792 0.987998H5.148L11.808 10.204L18.432 0.987998H22.788L14.184 12.652L23.328 25Z"/>
    </svg>
</template>

<script>
export default {}
</script>

<style lang="scss">
.close-icon {
    fill: #8C95A1;
}
</style>
(($) => {
    $('.js-toggle-filters').on('click', (event) => {
        const $currentTarget = $(event.target).closest('.filters-roster');

        event.preventDefault();

        $currentTarget.toggleClass('has-expanded-filters')
    });

    $('.js-filter, .js-filters-clear').on('click', (event) => {
        event.preventDefault();

        if ( $(event.target).hasClass('js-filters-clear') ) {
            $(event.target).closest('.filters__buttons').siblings('.filters__fields').find('input, select').val('');
        }

        const $form      = $(event.target).closest('.filters-roster');
        const resultsURL = $form.attr('action') + '?' + $form.serialize();

        updateResults(resultsURL);
    });

    $('.app-block-arbitrator').on('click', '.js-members-pagination a.page-numbers', (event) => {
        event.preventDefault();

        if ( $(event.target).hasClass('page-numbers') ) {
            updateResults($(event.target).attr('href'));
        } else {
            updateResults($(event.target).closest('.page-numbers').attr('href'));
        }
    });

    function updateResults(URL) {
        const $spinner = $('.loading-screen');

        if ( $('body').hasClass('members-loading') ) {
            return;
        }

        $.ajax({
            url: URL,
            beforeSend: () => {
                $('body').addClass('members-loading');
	            $spinner.addClass('show');
            },
            success: (response) => {
                const $newContent    = $(response).find('.js-members-listing');
                const $newPagination = $(response).find('.js-members-pagination');

                if ( $newContent.length ) {
                    $('.js-members-listing').html($newContent.html());
                }

                if ( $newPagination.length ) {
                    $('.js-members-pagination').html($newPagination.html());
                }

                $('html, body').animate({
                    scrollTop: $('.js-members-listing').offset().top - 300,
                }, 1000);
            },
            complete: () => {
                $('body').removeClass('members-loading');
	            $spinner.removeClass('show');
            }
        });
    }
})(jQuery);
<template>
    <div class="member-popup__container">
        <Teleport to="body">
            <member-modal class="member-popup" :show="showPopup" @close="onPopupClose">
                <template #header>
                    <h3 class="member-popup__title">Welcome To <span>ITechLaw</span></h3>
                </template>
                <template #body>
                    <div class="member-popup__content">
                        <p>We encourage you to join our vibrant community of law professionals to get the latest news
                            as well as access to an incredible network of other law professionals.</p>
                    </div>
                    <div class="member-popup__btn-group">
                        <a class="btn member-popup__btn" :href="loginHref">
                            Join Now or Login
                        </a>
                        <a class="btn member-popup__btn member-popup__btn--more" href="/membership/">
                            Learn More
                        </a>
                    </div>
                </template>
                <template #footer></template>
            </member-modal>
        </Teleport>
    </div>
</template>

<script>
import { ref, inject, onMounted } from 'vue';
import Modal from "./Modal";

// when an element with this class is clicked, it will trigger
// the member popup to display (even after it's already been
// manually hidden by the user)
const MEMBER_POPUP_TRIGGER_CLASS = '.member-popup-trigger';

const POPUP_HIDDEN_KEY = 'hideMemberPopup';

export default {
    components: {
        'member-modal': Modal,
    },
    props: {
        delay: {
            type: Number,
            default: 2000,
        }
    },

    setup(props, { emit }) {
        const manuallyHidden = ref(false);
        const showPopup = ref(false);

        const ipAddressHasUser = inject('ipAddressHasUser');
        const registerHref = inject('registerHref');
        const loginHref = inject('loginHref');

        const displayPopup = () => {
            showPopup.value = true;
        };

        const setPopupHiddenStatus = (status = 'hidden') => {
            return localStorage.setItem(POPUP_HIDDEN_KEY, status);
        }

        const removePopupHiddenStatus = () => {
            return localStorage.removeItem(POPUP_HIDDEN_KEY);
        }

        const getPopupHiddenStatus = () => {
            return localStorage.getItem(POPUP_HIDDEN_KEY);
        }

        const hasPopupBeenHidden = () => {
            let status = getPopupHiddenStatus();
            return status ? status === 'hidden' : false;
        }

        const onPopupClose = () => {
            showPopup.value = false;
            setPopupHiddenStatus('hidden');
        }

        const onPopupTriggerClick = (e) => {
            e.preventDefault();
            openPopup();
        };

        const openPopup = () => {
            removePopupHiddenStatus();
            manuallyHidden.value = false;

            setTimeout(() => {
                displayPopup();
            }, 100);
        }

        const registerPopupClickListener = () => {
            const elements = document.querySelectorAll(MEMBER_POPUP_TRIGGER_CLASS);
            elements.forEach(element => {
                element.addEventListener('click', onPopupTriggerClick);
            });
        }

        onMounted(() => {
            manuallyHidden.value = hasPopupBeenHidden();

            if (!manuallyHidden.value && !ipAddressHasUser.value) {
                setTimeout(() => {
                    displayPopup();
                }, props.delay);
            }

            registerPopupClickListener();
        });

        return {
            showPopup,
            onPopupClose,
            registerHref,
            loginHref,
            ipAddressHasUser,
        };
    },
}
</script>

<style lang="scss">
.member-popup {
    &__title {
        text-align: center;
        color: #0b2343;

        span {
            color: #FF5C35;
        }
    }

    &__content {
        text-align: center;
        margin-bottom: 3rem;
        font-size: 1.1em;
    }

    .member-popup__btn {
        width: 100%;
        background: #FF5C35;
        border: 2px solid #FF5C35;

        &:hover {
            background: #DA5A3B;
            border: 2px solid #DA5A3B;
        }

        &--more {
            background: transparent;
            color: #FF5C35;
            border: 2px solid #FF5C35;

            &:hover {
                color: #fff;
                background: #FF5C35;
                border: 2px solid #FF5C35;
            }
        }

        &-group {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 1.5rem;
        }
    }

    @media (min-width: 500px) {
        .member-popup__btn {
            width: auto;

            &-group {
                flex-direction: row;
            }
        }
    }
}
</style>
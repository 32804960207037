import { createApp, ref, provide, onBeforeMount } from "vue";
import config from './../config.json';
import components from '@/components/global';
import usePluginData from "@/composables/use-plugin-data";

let plugin_prefix = config.plugin_prefix;

let vue_prefix = plugin_prefix.replaceAll('_', '-');

let plugin_data_obj = plugin_prefix + 'data';

let vue_wrapping_class = window[plugin_data_obj].vue_wrapping_class;

let elements = document.querySelectorAll(`.${vue_prefix}${vue_wrapping_class}`);

let registered = [];

function register(elements) {
    for (let element of elements) {
        if (registered.includes(element)) {
            continue;
        }
        let pluginApp = createApp({
            setup() {
                const user = ref(null);
                const ipAddress = ref(null);
                const ipAddressHasUser = ref(null);
                const registerHref = ref(null);
                const loginHref = ref(null);

                provide('user', user);
                provide('ipAddress', ipAddress);
                provide('ipAddressHasUser', ipAddressHasUser);
                provide('registerHref', registerHref);
                provide('loginHref', loginHref);

                const { pluginData } = usePluginData(plugin_data_obj);

                onBeforeMount(() => {
                    user.value = pluginData.value.user;
                    ipAddress.value = pluginData.value.ip_address;
                    ipAddressHasUser.value = !!pluginData.value.ip_address_has_user ? true : false;
                    registerHref.value = pluginData.value.register_href;
                    loginHref.value = pluginData.value.login_href;
                });
            }
        });

        for (const [name, component] of Object.entries(components)) {
            pluginApp.component(name, component);
        }

        pluginApp.mount(element);

        registered.push(element);
    }
}

register(elements);

window.addEventListener('DOMContentLoaded', () => {
    elements = document.querySelectorAll(`.${vue_prefix}${vue_wrapping_class}`);
    register(elements);
});